import { FC } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import styled, { css } from 'styled-components';
import { mobile } from './breakpoints';
import { darkOrange, grey, lightGreyBackground, orange, white } from './colors';

const BootstrapButton: FC<ButtonProps> = ({ children, ...rest }) => (
  <Button {...rest}>{children}</Button>
);

export const CancelButton = styled(BootstrapButton)`
  color: ${props => props.theme.buttons.cancelTextColor};
  background-color: ${props => props.theme.buttons.cancelBackgroundColor};
  border-color: ${props => props.theme.buttons.cancelBorderColor};
  padding: 0.5rem 1rem;
  width: 40%;
`;

export const SubmitButton = styled(BootstrapButton)`
  color: ${props => props.theme.buttons.submitTextColor};
  background-color: ${props => props.theme.buttons.submitBackgroundColor};
  border-color: ${props => props.theme.buttons.submitBorderColor};
  padding: 0.5rem 1rem;
  width: 40%;
`;

export const LoginButton = styled.button`
  color: ${white};
  background-color: ${orange};
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  max-width: 6em;
  font-size: 18pt;
  letter-spacing: 0.12em;
  text-transform: uppercase;

  & .spinner-border {
    margin-right: 1em;
  }
`;

export const DisabledButtonColors = css`
  color: ${grey};
  background-color: ${lightGreyBackground};
  border-color: ${lightGreyBackground};
`;

export const CustomButton = styled(BootstrapButton)`
  background-color: ${props => props.theme.buttons.backgroundColor};
  border-color: ${props => props.theme.buttons.backgroundColor};
  color: ${props => props.theme.buttons.text};
  width: ${props => props.theme.buttons.width};

  &:hover {
    background-color: ${props => props.theme.buttons.hoverBackgroundColor};
    border-color: ${props => props.theme.buttons.hoverBorderColor};
  }

  &:disabled {
    background-color: ${props => props.theme.buttons.disabledBackgroundColor};
    border-color: ${props => props.theme.buttons.disabledBorderColor};
  }
`;

export const AuthPagesMainBtn = styled.button`
  background-color: ${props => props.theme.buttons.authBckgdColor};
  color: ${props => props.theme.buttons.authBtnTextColor};
  width: fit-content;
  min-width: 150px; /* Ensures loading spinner displays inside button. */
  padding: 6.5px 20px;
  border: none;
  border-radius: 4px;
  font-family: KanitMedium;
  font-size: 18px;
  line-height: 148%;
  letter-spacing: 0.12em;
  cursor: pointer;

  &:hover {
    background-color: ${darkOrange};
  }

  &:disabled {
    background-color: ${props => props.theme.buttons.disabledAuthBckgdColor};
    color: ${props => props.theme.buttons.disabledAuthBtnTextColor};
    cursor: unset;
  }

  @media (max-width: ${mobile}) {
    font-size: 18px;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  & button {
    margin-right: 10px;
  }

  & button:last-of-type {
    margin-right: 0;
  }
`;

export const RemoveButton = styled.button`
  height: fit-content;
  align-self: center;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0 1px 1px 1px;
`;
