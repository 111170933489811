import { DayInfo, getUSFormattedDate } from 'utils/dateTimeHelpers';
import { generateWeekEndString } from 'utils/helperFunction';

// Default values for all rows:
export const toInvoiceId = (day: DayInfo) => generateWeekEndString(day);
export const generateInvoiceDate = () => getUSFormattedDate(new Date());
export const bankId = 'VRB';
export const advanceTransCode = '';
export const advanceDescription = '';

// Default values for Pick and Haul rows:
export const pickHaulGlId = '260-10';
export const pickDesc = 'P';
export const haulDesc = 'H';
export const pickHaulTransCode = 'CH';
export const pickHaulPostToGrower = 'P';

// Default values for Transfer row:
export const transferApDesc = 'T';
export const transferTransCode = '';
export const transferPostToGrower = 'N';

// Default values for MISC rows:
export const miscApDesc = 'M';
export const miscTransCode = '';
export const miscPostToGrower = 'N';
