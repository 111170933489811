import { DateWithTime, DateWithoutTime } from 'common/models/dateType';
import { FuelSurcharge, TransferRate } from './hauler';

/**
 * Enum used for a harvesting invoice item description.
 */
export enum HarvestLineType {
  Harvesting,
  Breaks,
  Minimum_Wage,
  NPT,
  Supervision,
  Operator,
  Forklift,
  Commission,
}

/**
 * Enum used for a hauling invoice item description.
 */
export enum HaulingLineType {
  Hauling,
  Tarps,
  Fuel_Surcharge,
  Commission,
  Minimum_Load,
  Minimum_Load_Surcharge,
}

/**
 * Enum used to track transfer invoice item descriptions. It is not used
 * server-side.
 */
export enum TransferLineType {
  Transfer,
  Tarps,
  Commission,
  Fuel_Surcharge,
}

/**
 * Enum used to track an invoice item for a pick. It is not used
 * server-side.
 */
export enum MiscLineType {
  MISC,
}

/**
 * Enum used to track the order of invoice summary line item types.
 */
export enum InvoiceLineType {
  Harvest,
  Hauling,
  MISC,
  Transfer,
}

/**
 * Enum for the status of a harvest or hauling invoice
 * line item.
 */
export enum LineItemStatus {
  /** Created along with the pick record. */
  Initial,
  /** Affected by a pick record edit. */
  Edited,
  /** Saved along with the invoice. */
  Reviewed,
}

export type HarvestInvoiceItem = {
  id?: number;
  contractorRecordId: number;
  quantity: number | null;
  rate: number;
  description: HarvestLineType;
  subtotal: number | null;
  status: LineItemStatus;
};

export type HaulingInvoiceItem = {
  id?: number;
  contractorRecordId: number | null;
  haulerRecordId: number | null;
  quantity: number | null;
  rate: number;
  description: HaulingLineType;
  subtotal: number | null;
  status: LineItemStatus;
};

export type MiscInvoiceItem = {
  id?: number;
  pickId: number;
  contractorId: number;
  quantity: number;
  rate: number;
  note: string;
  subtotal: number;
  status: LineItemStatus;
};

export type TransferInvoiceItem = {
  id?: number;
  contractorId: number;
  date: DateWithoutTime;
  details: string;
  rate: TransferRate;
  glNumber: string;
  loads: number;
  commissionRate: number;
  surcharge: FuelSurcharge;
  subtotal: number;
};

export type ContractorInvoicePick = {
  pickId: number;
  pickDay: DateWithTime;
  growerId: string;
  blockId: string;
  poolId: string;
  contractorId: number;
  vendorId: string;
  harvestItems: HarvestInvoiceItem[];
  haulingItems: HaulingInvoiceItem[];
  miscItems: MiscInvoiceItem[];
};

export type PayrollData = {
  picks: ContractorInvoicePick[];
  transfers: TransferInvoiceItem[];
};

export type InvoiceSummaryLine = {
  date: DateWithTime;
  contractorId: number;
  vendorId: string;
  poolId: string;
  growerId: string;
  blockId: string;
  glNumber: string;
  subtotal: number;
  quantity: number;
  contractorCode: string;
  type: InvoiceLineType;
  newCount: number;
  editedCount: number;
};
