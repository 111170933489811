import { useAppDispatch, useAppSelector } from 'app/redux';
import { FormButtonHeader } from 'common/components/FormHeader/FormButtonHeader';
import TooltipTrigger from 'common/components/WithTooltip/WithTooltip';
import {
  HarvestLineType,
  HaulingLineType,
  LineItemStatus,
} from 'common/models/harvestData/payrollData';
import { useSavePayroll } from 'features/harvest-payroll/hooks/useSavePayroll';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import {
  PayrollTableRow,
  picksHeaders,
  transfersHeaders,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { FC } from 'react';
import { PickRows } from './PickRows';
import {
  EmptyHeaderCell,
  EmptyTotalCell,
  FormHeader,
  NeedsReviewBadge,
  NewPickBadge,
  TableContainer,
  Total,
  TotalPrompt,
} from './styles';
import { TransferRows } from './TransferRows';

/** Component that handles the display of either the picks or transfers table  */
export const PayrollTable: FC<{
  tableData: PayrollTableRow[];
  tableTotalRow: PayrollTableRow;
  /** When `true`, the the payroll form edit button displays. Default is `false`. */
  isPicksTable?: boolean;
  /** Function to reset table data to its origininal state */
  setInitialTableData?: () => void;
}> = ({
  isPicksTable = true,
  tableData,
  tableTotalRow,
  setInitialTableData,
}) => {
  const dispatch = useAppDispatch();
  const { handleSavePayroll } = useSavePayroll();
  const { isEditable, isDirty } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const isNew = tableData.some(row => {
    const isPickHeader =
      row.pickDay &&
      (row.metadata?.descType === HarvestLineType[HarvestLineType.Harvesting] ||
        row.metadata?.descType === HaulingLineType[HaulingLineType.Hauling]);

    // This makes it so that we only show the new badge when there's a pick we
    // can actually highlight as new. Specifically, when a pick has both new
    // items and items needing review, we can't highlight the header row because
    // it loses its new status when the pick is edited - we don't want to show
    // the new badge in that case as it would be visually inconsistent.
    return isPickHeader && row.metadata?.status === LineItemStatus.Initial;
  });
  const needsReview = tableData.some(
    row => row.metadata?.status === LineItemStatus.Edited,
  );

  const handleEditClick = async () => {
    if (isEditable && (isDirty || isNew || needsReview)) {
      const isSuccess = await handleSavePayroll();
      if (!isSuccess) {
        return;
      }
    }
    dispatch(payrollSlice.actions.setIsEditable(!isEditable));
  };

  const handleCancelClick = () => {
    if (setInitialTableData && isDirty) {
      setInitialTableData();
      dispatch(payrollSlice.actions.setIsDirty(false));
    }
    dispatch(payrollSlice.actions.setIsEditable(false));
  };

  return isPicksTable ? (
    <>
      <FormHeader>
        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
          <h4>Picks</h4>
          {isNew && (
            <TooltipTrigger
              title='Pick is new legend.'
              tooltipText='Indicates a new pick was added.'
            >
              <NewPickBadge>New</NewPickBadge>
            </TooltipTrigger>
          )}
          {needsReview && (
            <TooltipTrigger
              title='Pick needs review legend.'
              tooltipText='Indicates a pick record change affected rows.'
            >
              <NeedsReviewBadge>Needs review</NeedsReviewBadge>
            </TooltipTrigger>
          )}
        </div>
        <FormButtonHeader
          isEditable={isEditable}
          handleCancelClick={handleCancelClick}
          handleEditClick={handleEditClick}
        />
      </FormHeader>
      <TableContainer>
        {picksHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <PickRows tableData={tableData} isEditable={isEditable} />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  ) : (
    <>
      <FormHeader>
        <h4>Transfers</h4>
      </FormHeader>
      <TableContainer>
        {transfersHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <TransferRows tableData={tableData} isEditable={isEditable} />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  );
};
