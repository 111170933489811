import { DisabledButtonColors } from 'common/styles/button';
import { lighterNavy } from 'common/styles/colors';
import { FC } from 'react';
import { ButtonProps, Spinner } from 'react-bootstrap';
import styled, { css } from 'styled-components';

interface LoadingButtonProps extends Omit<ButtonProps, 'as'> {
  loading: boolean;
  as: FC<ButtonProps>;
}

export const LoadingButton: FC<LoadingButtonProps> = ({
  as: ButtonComponent,
  loading,
  children,
  disabled,
  ...buttonProps
}) => {
  return (
    <ButtonComponent {...buttonProps} disabled={disabled || loading}>
      {children}
      {loading && (
        <Spinner
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
          style={{ margin: '0 -21px 0 5px' }}
        />
      )}
    </ButtonComponent>
  );
};

export const ButtonStyling = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 10px;
  margin-top: 10%;
  gap: 10px;

  width: 150px;
  height: 40px;

  background: ${lighterNavy};
  border-radius: 4px;

  font-family: 'KanitRegular';
  font-weight: 500;
  font-size: 18px;

  :disabled {
    ${DisabledButtonColors}
  }
`;

export const StyledLoadingButton = styled(LoadingButton)`
  ${ButtonStyling}
`;
