import {
  blueShade,
  green,
  highlightOrange,
  lightBlue,
  tableBodyGrey,
  tableHeaderGrey,
} from 'common/styles/colors';
import styled from 'styled-components';

export const FormHeader = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  h4 {
    margin-right: 10px;
  }
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr 1fr 4fr 1fr 1fr 0.25fr;
  width: 100%;
`;

export const TableHeaderCell = styled.div`
  padding: 10px 5px;
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${tableHeaderGrey};
  border-bottom: 2px solid ${tableHeaderGrey};
  margin-bottom: 1px;
`;

export const EmptyHeaderCell = styled(TableHeaderCell)``;

export const NewPickBadge = styled.div`
  height: 24px;
  margin: 0 0 3px 5px;
  padding: 0 5px;
  border-radius: 5px;
  font-family: KanitLight;
  border: 2px solid ${green};
  display: flex;
  align-items: center;
`;

export const ValueChangedWrap = styled.div`
  background: ${highlightOrange};
  padding: 0 5px;
  border-radius: 5px;
  font-family: KanitLight;
`;

export const NeedsReviewBadge = styled(ValueChangedWrap)`
  margin: 0 0 3px 5px;
`;

export type Borders = 'top' | 'right' | 'bottom' | 'left';

export const TableCell = styled.div<{
  $isTotalLine?: boolean;
  $isEditable?: boolean;
  $changeBackground?: boolean;
  $isNew?: Borders[];
}>`
  padding: 10px 5px;
  font-family: ${props => (props.$isTotalLine ? 'KanitMedium' : 'KanitLight')};
  font-size: 16px;
  color: ${tableBodyGrey};
  display: flex;
  justify-content: space-between;
  background: ${props => {
    if (props.$changeBackground) {
      return props.$isEditable ? blueShade : lightBlue;
    }
    return 'none';
  }};
  ${props => {
    if (props.$isNew) {
      return props.$isNew
        .map(border => `border-${border}: 2px solid ${green};`)
        .join('');
    }
    return '';
  }}

  div {
    font-family: KanitLight;
    font-size: 16px;
    color: ${tableBodyGrey};
  }
`;

export const EmptyCell = styled(TableCell)`
  min-height: 34px;
`;

export const QuantityCell = styled(TableCell)`
  justify-content: center;
`;

export const RateCell = styled(TableCell)`
  justify-content: center;
`;

export const SubtotalCell = styled(TableCell)`
  justify-content: flex-end;
`;

export const RemoveRowCell = styled(TableCell)`
  padding: 0;
`;

export const TotalPrompt = styled(TableCell)`
  font-family: KanitSemiBold;
  font-size: 18px;
  grid-column-start: 1;
  grid-column-end: 7;
  text-align: end;
  padding-right: 30px;
  border-top: 2px solid ${tableBodyGrey};
  justify-content: flex-end;
`;

export const NoTransfersCell = styled(TableCell)`
  grid-column-start: 5;
  grid-column-end: 6;
`;

export const Total = styled(TotalPrompt)`
  grid-column-start: 7;
  grid-column-end: 8;
  padding-right: 5px;
`;

export const EmptyTotalCell = styled(TotalPrompt)`
  grid-column-start: 8;
  grid-column-end: 9;
`;
