import ErrorIcon from '@mui/icons-material/Error';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import { InvoiceSummaryLine } from 'common/models/harvestData/payrollData';
import currency from 'currency.js';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';
import { useMemo } from 'react';
import { Column } from 'react-table';
import WithTooltip from '../../../common/components/WithTooltip/WithTooltip';

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const headerCenterStyle = {
  ...centerStyle,
  fontFamily: 'KanitSemiBold',
  fontSize: 16,
};

export type PayrollSumTableItem = {
  status: {
    hasNew: boolean;
    hasEdits: boolean;
  };
  contractorId: number;
  contractorCode: string;
  vendorId: string;
  total: string;
};

export type UsePayrollSumTableData = (rows: InvoiceSummaryLine[]) => {
  columns: Column<PayrollSumTableItem>[];
  data: PayrollSumTableItem[];
};

export const usePayrollSumTableData: UsePayrollSumTableData = (rows = []) => {
  const columns: Column<PayrollSumTableItem>[] = useMemo(
    () => [
      {
        accessor: 'status',
        Header: () => <span style={headerCenterStyle}>Status</span>,
        width: '5%',
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {value.hasNew && (
              <WithTooltip
                title='New lines icon'
                tooltipText='New invoice or new lines added.'
              >
                <NoteAddOutlinedIcon color='success' />
              </WithTooltip>
            )}
            {value.hasEdits && (
              <WithTooltip
                title='Needs review icon'
                tooltipText='Pick record(s) changed.'
              >
                <ErrorIcon color='warning' />
              </WithTooltip>
            )}
            {!value.hasNew && !value.hasEdits && (
              <WithTooltip
                title='Save success icon'
                tooltipText='Invoice reviewed.'
              >
                <FactCheckIcon color='success' />
              </WithTooltip>
            )}
          </DataTableText>
        ),
      },
      {
        accessor: 'contractorCode',
        Header: () => <span style={headerCenterStyle}>Code</span>,
        width: '10%',
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>{value}</DataTableText>
        ),
      },
      {
        accessor: 'vendorId',
        Header: 'Vendor ID',
        Cell: ({ value }) => value,
      },
      {
        accessor: 'total',
        Header: 'Total',
        Cell: ({ value }) => value,
      },
      {
        id: 'Arrow',
        Header: '',
        width: '3%',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );

  const data: PayrollSumTableItem[] = useMemo(
    () =>
      rows.reduce((acc: PayrollSumTableItem[], row: InvoiceSummaryLine) => {
        const existingRow = acc.find(r => r.vendorId === row.vendorId);

        if (!existingRow) {
          acc.push({
            status: {
              hasNew: row.newCount > 0,
              hasEdits: row.editedCount > 0,
            },
            contractorId: row.contractorId,
            contractorCode: row.contractorCode,
            vendorId: row.vendorId,
            total: currency(row.subtotal).format(),
          });
        } else {
          existingRow.status = {
            hasNew: existingRow.status.hasNew || row.newCount > 0,
            hasEdits: existingRow.status.hasEdits || row.editedCount > 0,
          };
          existingRow.total = currency(existingRow.total)
            .add(row.subtotal)
            .format();
        }

        return acc;
      }, []),
    [rows],
  );

  return { columns, data };
};
