import {
  HarvestInvoiceItem,
  HarvestLineType,
  HaulingInvoiceItem,
  HaulingLineType,
  MiscInvoiceItem,
  MiscLineType,
  TransferInvoiceItem,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import currency from 'currency.js';
import {
  PayrollTableRow,
  emptyPayrollRow,
  totalIdKey,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { getISODateOnlyString } from 'utils/dateTimeHelpers';
import { toEnumLabel } from 'utils/enumFunctions';
import { PayrollRowMap, TransfersTableRows } from '../PayrollSlice';
import { createRowSubtotal, createTableDate } from './payrollPageUtils';
import { tarpRate } from './tableRowDefaults';

type PickMetadata = {
  pickId: number;
  blockId: string;
  pickDate: string;
  pickInfo: string;
};

/** Contains the pick table rows for a single contractor record. */
type ContractorPickRows = {
  defaultRows: PayrollTableRow[];
  commissionRow: PayrollTableRow;
  customRows: PayrollTableRow[];
};

/** Represents a function that will process a group of rows after it's put together. */
type GroupHandler = (
  rows: PayrollTableRow[],
  recId: number,
) => PayrollTableRow[];

const createRowContainer = (): ContractorPickRows => ({
  defaultRows: [],
  commissionRow: { ...emptyPayrollRow },
  customRows: [],
});

const toPlainMap = (
  rowMap: Map<number, ContractorPickRows>,
  options?: { pickData?: PickMetadata; onGroup?: GroupHandler },
): PayrollRowMap => {
  const onGroup = options?.onGroup ?? (rows => rows);
  const map = Array.from(rowMap.entries()).reduce((all, { 0: id, 1: ctr }) => {
    const rowGroup = [...ctr.defaultRows, ...ctr.customRows, ctr.commissionRow];

    all[id] = onGroup(rowGroup, id);

    return all;
  }, {} as PayrollRowMap);

  // Assign pick info, if given. To ensure this is shown for the very first row
  // in a pick, this must be done at this point because the order of row groups
  // can change as we convert to a plain map above, due to how JS objects work
  // (property insertion order does not guarantee iteration order).
  if (options?.pickData) {
    const { blockId, pickDate, pickInfo } = options.pickData;

    const firstGroup = map[parseInt(Object.keys(map)[0], 10)];
    firstGroup[0].pickDay = pickDate;
    firstGroup[0].pick = pickInfo;
    firstGroup[0].block = blockId;
  }

  return map;
};

/**
 * Converts harvest invoice items into pick table rows, grouped by contractor
 * record and returned in the order in which they should be displayed.
 *
 * @returns A map of contractor record IDs to their corresponding pick table rows.
 *
 * @remarks
 *
 * This function operates under the assumption that it will handle pick rows for
 * a single contractor. In most cases, this means that the returned map will only
 * ever contain a single entry.
 *
 * However, it is possible, though uncommon, for the *same* contractor to have
 * multiple records in the same pick. This will happen when users want to reflect
 * that the contractor will be paid differently for a portion (or, portions) of
 * the same pick. In this case, the returned map will contain multiple entries.
 */
export const formatHarvestRows = (
  pickMetadata: PickMetadata,
  rows: HarvestInvoiceItem[],
  onGroup?: GroupHandler,
): PayrollRowMap => {
  const rowsPerRecord = new Map<number, ContractorPickRows>();
  const { pickId } = pickMetadata;

  rows.forEach(
    ({ id, contractorRecordId, description, rate, quantity, status }) => {
      const contractorRows =
        rowsPerRecord.get(contractorRecordId) ??
        (rowsPerRecord
          .set(contractorRecordId, createRowContainer())
          .get(contractorRecordId) as ContractorPickRows);
      const rowValues: PayrollTableRow = {
        ...emptyPayrollRow,
        metadata: {
          pickId,
          contractorRecordId,
          haulerRecordId: null,
          itemId: id as number,
          discriminator: null,
          descCategory: HarvestLineType[HarvestLineType.Harvesting],
          descType: HarvestLineType[description],
          invItemType: 'harv',

          status,
        },
        quantity: quantity?.toString() || '',
        rate: currency(rate).format(),
        subtotal: quantity ? createRowSubtotal(rate, quantity) : '',
        description: toEnumLabel(HarvestLineType[description]),
      };

      switch (description) {
        case HarvestLineType.Harvesting:
          contractorRows.defaultRows[0] = { ...rowValues };
          break;
        case HarvestLineType.Supervision:
          contractorRows.defaultRows[1] = { ...rowValues };
          break;
        case HarvestLineType.Operator:
          contractorRows.defaultRows[2] = { ...rowValues };
          break;
        case HarvestLineType.Forklift:
          contractorRows.defaultRows[3] = { ...rowValues };
          break;
        case HarvestLineType.Commission:
          contractorRows.commissionRow = {
            ...rowValues,
            rate: rate.toString(),
          };
          break;
        default:
          contractorRows.customRows.push({ ...rowValues });
      }
    },
  );

  return toPlainMap(rowsPerRecord, { pickData: pickMetadata, onGroup });
};

/**
 * Converts hauling invoice items into pick table rows, grouped by contractor
 * record and returned in the order in which they should be displayed.
 *
 * @returns A map of contractor record IDs to their corresponding pick table rows.
 *
 * @remarks
 *
 * This function expects to handle pick rows for a single contractor, but note
 * that there is a legitimate case where it will return a map with multiple
 * entries, see remarks on {@link formatHarvestRows} for details.
 */
export const formatHaulRows = (
  pickMetadata: PickMetadata,
  rows: HaulingInvoiceItem[],
  hasHarvestRows: boolean,
  onGroup?: GroupHandler,
): PayrollRowMap => {
  const rowsPerRecord = new Map<number, ContractorPickRows>();
  const { pickId } = pickMetadata;

  rows.forEach(
    ({
      id,
      contractorRecordId,
      haulerRecordId,
      description,
      rate,
      quantity,
      status,
    }) => {
      const recordId = contractorRecordId ?? (haulerRecordId as number);
      const contractorRows =
        rowsPerRecord.get(recordId) ??
        (rowsPerRecord
          .set(recordId, createRowContainer())
          .get(recordId) as ContractorPickRows);
      const rowValues: PayrollTableRow = {
        ...emptyPayrollRow,
        metadata: {
          pickId,
          contractorRecordId: contractorRecordId || null,
          haulerRecordId: haulerRecordId || null,
          itemId: id as number,
          discriminator: null,
          descCategory: HaulingLineType[HaulingLineType.Hauling],
          descType: HaulingLineType[description],
          invItemType: 'haul',
          status,
        },
        quantity: quantity?.toString() || '',
        rate: HaulingLineType[HaulingLineType.Commission]
          ? currency(rate).format()
          : rate.toString(),
        subtotal: quantity ? createRowSubtotal(rate, quantity) : '',
        description: toEnumLabel(HaulingLineType[description]),
      };

      switch (description) {
        case HaulingLineType.Hauling:
          contractorRows.defaultRows[0] = { ...rowValues };
          break;
        case HaulingLineType.Tarps:
          contractorRows.defaultRows[1] = { ...rowValues };
          break;
        case HaulingLineType.Fuel_Surcharge:
          contractorRows.defaultRows[2] = { ...rowValues };
          break;
        case HaulingLineType.Commission:
          contractorRows.commissionRow = {
            ...rowValues,
            rate: rate.toString(),
          };
          break;
        default:
          contractorRows.customRows.push({
            ...emptyPayrollRow,
            ...rowValues,
          });
      }
    },
  );

  return toPlainMap(rowsPerRecord, {
    pickData: hasHarvestRows ? undefined : pickMetadata,
    onGroup,
  });
};

/** Helper function to convert miscellaneous invoice items into picks table rows. */
export const formatMiscRows = (rows: MiscInvoiceItem[]): PayrollTableRow[] => {
  const defaultRows = [] as PayrollTableRow[];

  rows.forEach(({ id, pickId, note, rate, quantity, status }) => {
    const rowValues: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        pickId,
        contractorRecordId: null,
        haulerRecordId: null,
        itemId: id as number,
        discriminator: null,
        descCategory: MiscLineType[MiscLineType.MISC],
        descType: MiscLineType[MiscLineType.MISC],
        invItemType: 'null',
        status,
      },
      quantity: quantity.toString(),
      rate: currency(rate).format(),
      subtotal: createRowSubtotal(rate, quantity),
      description: note,
    };

    defaultRows.push(rowValues);
  });

  return defaultRows;
};

/** Helper function to convert transfer invoice items into transfers table rows. */
export const formatTransferRows = (
  transfers: TransferInvoiceItem[],
): { rows: TransfersTableRows[]; count: number } => {
  const rows: TransfersTableRows[] = [];
  let count = 1;

  transfers.forEach(transfer => {
    const {
      rate: { chargeTarps },
    } = transfer;

    const routeRow: PayrollTableRow = {
      metadata: {
        ...emptyPayrollRow.metadata,
        itemId: transfer.id as number,
        discriminator: count,
        descCategory: TransferLineType[TransferLineType.Transfer],
        descType: TransferLineType[TransferLineType.Transfer],
        transferDate: getISODateOnlyString(transfer.date),
      },
      pick: transfer.details,
      pickDay: createTableDate(transfer.date),
      block: transfer.glNumber,
      quantity: transfer.loads.toString(),
      description: transfer.rate.route,
      rate: currency(transfer.rate.rate).format(),
      subtotal: createRowSubtotal(transfer.rate.rate, transfer.loads),
    };

    const tarpRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...emptyPayrollRow.metadata,
        itemId: transfer.id as number,
        descCategory: TransferLineType[TransferLineType.Transfer],
        descType: TransferLineType[TransferLineType.Tarps],
        chargeTarps,
      },
      quantity: chargeTarps ? transfer.loads.toString() : '0',
      description: TransferLineType[TransferLineType.Tarps],
      rate: chargeTarps ? currency(tarpRate).format() : '$0',
      subtotal: chargeTarps
        ? createRowSubtotal(tarpRate, transfer.loads)
        : '$0',
    };

    const surchargeRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...emptyPayrollRow.metadata,
        itemId: transfer.id as number,
        descCategory: TransferLineType[TransferLineType.Transfer],
        descType: TransferLineType[TransferLineType.Fuel_Surcharge],
        surchargeId: transfer.surcharge.id,
      },
      quantity: transfer.loads.toString(),
      description: toEnumLabel(
        TransferLineType[TransferLineType.Fuel_Surcharge],
      ),
      rate: currency(transfer.surcharge.perLoad).format(),
      subtotal: createRowSubtotal(transfer.surcharge.perLoad, transfer.loads),
    };

    const commRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...emptyPayrollRow.metadata,
        itemId: transfer.id as number,
        descCategory: TransferLineType[TransferLineType.Transfer],
        descType: TransferLineType[TransferLineType.Commission],
      },
      description: TransferLineType[TransferLineType.Commission],
      rate: `${transfer.commissionRate}%`,
      subtotal: createRowSubtotal(
        transfer.commissionRate,
        currency(tarpRow.subtotal).value,
        true,
      ),
    };

    rows.push({
      id: count,
      rows: [routeRow, tarpRow, surchargeRow, commRow],
      transferTotal: {
        ...emptyPayrollRow,
        metadata: {
          ...emptyPayrollRow.metadata,
          pickId: count,
          descCategory: TransferLineType[TransferLineType.Transfer],
          descType: totalIdKey,
        },
        rate: 'Total:',
      },
    });

    count += 1;
  });

  return { rows, count };
};
