import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { HarvestPayrollView } from './pages/HarvestPayrollView';
import { allContractorsCode } from './PayrollSlice';

export const HarvestPayrollRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${path}/:contractorId`}
        component={HarvestPayrollView}
        requiredRoles={[RoleType.Admin]}
      />
      <Redirect to={`${path}/${allContractorsCode}`} />
    </Switch>
  );
};
