import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from '../PayrollSlice';
import { createRowSubtotal, createTableDate } from '../utils/payrollPageUtils';
import {
  LineItemStatus,
  TransferInvoiceItem,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import { useAppDispatch, useAppSelector } from 'app/redux';
import currency from 'currency.js';
import { getISODateOnlyString } from 'utils/dateTimeHelpers';
import { ValidatedTransferRow } from '../components/PayrollTable/AddCustomLineForm/AddTransferLineForm';
import {
  RowMetadata,
  PayrollTableRow,
  emptyPayrollRow,
} from '../utils/payrollDataTypes';
import { tarpRate } from '../utils/tableRowDefaults';
import { formatTransferRows } from '../utils/payrollTableUtils';
import { toEnumLabel } from 'utils/enumFunctions';

/** Hook for transfers table actions. */
export const useTransferTable = () => {
  const dispatch = useAppDispatch();
  const { transfers } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  /** Method to create the transfers table rows from payroll data. */
  const createTransferDefaults = (
    defaultTransfers: TransferInvoiceItem[],
  ): void => {
    if (defaultTransfers.length > 0) {
      const { rows, count } = formatTransferRows(defaultTransfers);

      dispatch(
        payrollSlice.actions.setTransferRows({
          rows,
          updateTotal: false,
        }),
      );
      dispatch(payrollSlice.actions.incrementNewRowCount(count));
    } else {
      dispatch(
        payrollSlice.actions.setTransferRows({
          rows: [],
          updateTotal: false,
        }),
      );
    }
  };

  /** Method to add transfer rows. */
  const addTransferRow = (
    data: ValidatedTransferRow,
    discriminator: number,
  ): void => {
    const {
      date,
      details,
      glNumber,
      loads,
      rate: {
        value: { route, rate, surcharge, surchargeId, chargeTarps },
      },
      commissionRate,
    } = data;

    const tarpQuantity = currency(loads).value;
    const commRate = currency(commissionRate).value;
    const defaultTarpRate = currency(tarpRate);

    const defaultMetadata: RowMetadata = {
      pickId: -1,
      contractorRecordId: null,
      haulerRecordId: null,
      itemId: null,
      discriminator,
      descCategory: TransferLineType[TransferLineType.Transfer],
      descType: '',
      invItemType: 'null',
      transferDate: getISODateOnlyString(data.date),
      status: LineItemStatus.Reviewed,
    };

    const newTransferRow: PayrollTableRow = {
      metadata: {
        ...defaultMetadata,
        descType: TransferLineType[TransferLineType.Transfer],
      },
      pickDay: createTableDate(date),
      pick: details,
      block: glNumber,
      quantity: loads,
      description: route,
      rate: currency(rate).format(),
      subtotal: createRowSubtotal(rate, tarpQuantity),
    };

    const newTarpRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...defaultMetadata,
        descType: TransferLineType[TransferLineType.Tarps],
        chargeTarps,
      },
      description: TransferLineType[TransferLineType.Tarps],
      quantity: chargeTarps ? loads : '0',
      rate: chargeTarps ? defaultTarpRate.format() : '$0',
      subtotal: chargeTarps
        ? createRowSubtotal(defaultTarpRate.value, tarpQuantity)
        : '$0',
    };

    const newSurchargeRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...defaultMetadata,
        descType: TransferLineType[TransferLineType.Fuel_Surcharge],
        surchargeId,
      },
      description: toEnumLabel(
        TransferLineType[TransferLineType.Fuel_Surcharge],
      ),
      quantity: loads,
      rate: currency(surcharge).format(),
      subtotal: createRowSubtotal(currency(surcharge).value, tarpQuantity),
    };

    const newCommRow: PayrollTableRow = {
      ...emptyPayrollRow,
      metadata: {
        ...defaultMetadata,
        descType: TransferLineType[TransferLineType.Commission],
      },
      description: TransferLineType[TransferLineType.Commission],
      rate: `${commRate}%`,
      subtotal: createRowSubtotal(
        commRate,
        currency(newTarpRow.subtotal).value,
        true,
      ),
    };

    const newRows = {
      id: discriminator,
      rows: [newTransferRow, newTarpRow, newSurchargeRow, newCommRow],
      transferTotal: { ...emptyPayrollRow },
    };

    dispatch(
      payrollSlice.actions.setTransferRows({
        rows: transfers.length ? [...transfers, newRows] : [newRows],
        updateTotal: true,
      }),
    );
    dispatch(payrollSlice.actions.incrementNewRowCount());
  };

  /** Method to remove all of a transfer's rows. */
  const removeTransfer = (metadata: RowMetadata): void => {
    const { discriminator } = metadata;

    let transferRows = [...transfers];

    const recordIdx = transferRows.findIndex(
      record => record.id === discriminator,
    );
    const transferIdToDelete = transferRows[recordIdx].rows[0].metadata.itemId;

    transferRows = transferRows.toSpliced(recordIdx, 1);

    dispatch(
      payrollSlice.actions.setTransferRows({
        rows: transferRows,
        updateTotal: true,
      }),
    );

    if (transferIdToDelete) {
      dispatch(payrollSlice.actions.setTransfersToDelete(transferIdToDelete));
    }
  };

  return {
    createTransferDefaults,
    addTransferRow,
    removeTransfer,
  };
};
