import { ChevronRight } from '@mui/icons-material';
import { handleError } from 'common/api/handleError';
import { useDeletePickScheduleMutation } from 'common/api/pickSchedulingApi';
import TooltipTrigger from 'common/components/WithTooltip/WithTooltip';
import { RoleType } from 'common/models';
import * as notifier from 'common/services/notification';
import { white } from 'common/styles/colors';
import { useAuth } from 'features/auth/hooks';
import { useConfirmationModal } from 'features/confirmation-modal';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import {
  AssignBtn,
  ButtonSection,
  InputBtn,
  RemoveBtn,
  RemoveIcon,
} from './styles';

export const ScheduledPickButtons: FC<{
  isAssigned: boolean;
  isPicked: boolean;
  isEdit: boolean;
}> = ({ isAssigned, isPicked, isEdit }) => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [deletePickSchedule] = useDeletePickScheduleMutation();
  const { openModal } = useConfirmationModal();
  const { userHasPermission } = useRbac();
  const history = useHistory();
  const isFieldRep = user?.role.roleName === RoleType.FieldRep;
  const removeBtnDisabled = isFieldRep && isPicked;

  async function tryDeletePickSchedule(id: number) {
    try {
      await deletePickSchedule(id).unwrap();
      history.replace(Constants.routes.PICK_SCHEDULE);
      notifier.showSuccessMessage('Scheduled pick deleted.');
    } catch (error) {
      handleError(error, 'There was an issue deleting this scheduled pick.');
    }
  }

  function createModalBody() {
    return isPicked ? (
      <div style={{ textAlign: 'start', margin: '0 20px' }}>
        This scheduled pick already has harvest data. Deleting it will:
        <ul style={{ marginTop: '15px' }}>
          <li>Unassign the HC & crew,</li>
          <li>Delete pick record data,</li>
          <li>Delete the associated estimate,</li>
          <li>Delete the associated payroll invoice.</li>
        </ul>
        Are you sure you want to continue?
      </div>
    ) : (
      ''
    );
  }

  function openDeleteModal() {
    openModal({
      title: 'Delete scheduled pick',
      body: createModalBody(),
      confirmButtonLabel: 'DELETE',
      declineButtonLabel: 'CANCEL',
      onConfirm: () => tryDeletePickSchedule(parseInt(id, 10)),
    });
  }

  return isEdit ? (
    <></>
  ) : (
    <ButtonSection>
      {userHasPermission('pick:assign-crew') && (
        <AssignBtn
          type='button'
          $textColor={white}
          onClick={() =>
            history.push(
              `${Constants.routes.PICK_SCHEDULE}/${id}${Constants.routes.CREW_ASSIGNMENT}`,
            )
          }
          disabled={isPicked}
        >
          ASSIGN HC & CREW
          <ChevronRight />
        </AssignBtn>
      )}
      {userHasPermission('pick:input-data') && isAssigned && (
        <InputBtn
          type='button'
          $textColor={white}
          onClick={() =>
            history.push(
              `${Constants.routes.PICK_SCHEDULE}/${id}${Constants.routes.PICK_DATA}`,
            )
          }
        >
          INPUT PICK DATA
          <ChevronRight />
        </InputBtn>
      )}
      {userHasPermission('pick:remove') && (
        <TooltipTrigger
          title='Remove scheduled pick button.'
          tooltipText={
            removeBtnDisabled
              ? 'Only an admin can remove a scheduled pick with harvest data entered.'
              : ''
          }
        >
          <RemoveBtn
            type='button'
            onClick={() => openDeleteModal()}
            disabled={removeBtnDisabled}
          >
            <RemoveIcon /> REMOVE PICK
          </RemoveBtn>
        </TooltipTrigger>
      )}
    </ButtonSection>
  );
};
