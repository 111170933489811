import { useCallback } from 'react';
import * as notifier from 'common/services/notification';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import {
  useDeletePlanRowMutation,
  useUpdateBinRequestMutation,
} from 'common/api/packHouse.api';
import { useConfirmationModal } from 'features/confirmation-modal';
import { Market } from 'common/models/market';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';

export type UpdateBinReqData = {
  binId: number | null;
  packHouseId: number;
  varietyId: number;
  market: string;
  sizeId: number;
  bins: number;
  date: string;
};

export type RemovePlanRowData = {
  startDate: string;
  packHouseId: number;
  variety: number;
  market: string;
  size: number;
};

type PackPlanActions = {
  saveBinRequest: (formData: UpdateBinReqData) => Promise<boolean>;
  openRemoveRowModal: (
    startDate: string,
    packHouseId: number,
    variety: Variety,
    market: string,
    size: Size,
  ) => void;
};

const usePackPlanActions = (): PackPlanActions => {
  const { openModal } = useConfirmationModal();
  const [updateBinRequest] = useUpdateBinRequestMutation();
  const [deletePlanRow] = useDeletePlanRowMutation();

  const saveBinRequest = useCallback(
    async (binReqData: UpdateBinReqData) => {
      try {
        await updateBinRequest(binReqData).unwrap();
        notifier.showSuccessMessage('Bin request updated.');
        return true;
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        return false;
      }
    },
    [updateBinRequest],
  );

  const removePlanRow = useCallback(
    async (rowData: RemovePlanRowData) => {
      try {
        await deletePlanRow(rowData).unwrap();
        notifier.showSuccessMessage('Plan row was deleted.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
      }
    },
    [deletePlanRow],
  );

  const openRemoveRowModal = (
    startDate: string,
    packHouseId: number,
    variety: Variety,
    market: string,
    size: Size,
  ) => {
    const newMarketStr =
      Object.keys(Market)[(Object.values(Market) as string[]).indexOf(market)];
    openModal({
      title: `This will remove the ${variety.varietyCode}/${newMarketStr}/${size.value} row.`,
      confirmButtonLabel: 'CONFIRM',
      declineButtonLabel: 'CANCEL',
      onConfirm: () =>
        removePlanRow({
          startDate,
          packHouseId,
          variety: variety.id,
          market,
          size: size.id,
        }),
    });
  };

  return {
    saveBinRequest,
    openRemoveRowModal,
  };
};

export default usePackPlanActions;
